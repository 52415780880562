@font-face {
    font-family: 'Poppins-Regular';
    src: url('./fonts/Poppins-Regular.ttf') format('opentype');
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('./fonts/Poppins-Bold.ttf') format('opentype');
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url('./fonts/Montserrat-Regular.ttf') format('opentype');
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url('./fonts/Montserrat-Bold.ttf') format('opentype');
}

@font-face {
    font-family: 'Itau Text Pro Regular';
    src: url('./fonts/ItauTextPro_Rg.ttf') format('opentype');
}

@font-face {
    font-family: 'Itau Text Pro Bold';
    src: url('./fonts/ItauTextPro_Bd.ttf') format('opentype');
}

@font-face {
    font-family: 'Itau Text Pro Light';
    src: url('./fonts/ItauTextPro_Lt.ttf') format('opentype');
}

@font-face {
    font-family: 'Itau Text Pro X Bold';
    src: url('./fonts/ItauTextPro_XBd.ttf') format('opentype');
}

@font-face {
    font-family: 'TonCondensedVF';
    src: url('./fonts/TonCondensedVF.ttf') format('truetype');
}

@font-face {
    font-family: 'Sf Pro Display';
    src: url('./fonts/SfProDisplayMedium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('./fonts/SFProText-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('./fonts/SFProText-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('./fonts/Inter-VariableFont.ttf') format('truetype');
}
